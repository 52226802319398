.stars {
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 48px;
  }
  .on {
    color: rgb(255, 226, 52);
  }
  .off {
    color: gray;
    opacity: 20%;
  }