.card{
    margin: 10px auto;
    display: block;
    
}

.title-img-position{
    margin: 10px auto;
    max-width: 75%;
}

.center{
    text-align: center;
}

.card-text, .card-subtitle{
    margin: 10px auto;
    line-height: 2rem;
}

.ct-style{
    background-color: #D1D1D1;
    padding: 5px;
}