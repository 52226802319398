.footerBar{
    background-color: #d1d1d1;
    border-radius: 10px;
}

.footerBar p{
    text-align: center;
    
}

.svg-scaler{
    width: 32px;
    height: 32px;
}