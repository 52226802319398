.reviews {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: baseline;
  align-content: space-around;
  gap: 2px;
}

.card {
    text-align: center;
    align-self: center;
    width: auto;
  }

.card-text{
  text-align: left;
  width: auto;
  padding: 10px;
  margin: 10px auto;
}

.interview-title{
  padding: 10px;
}